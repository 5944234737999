<template>
  <div>
    <investment-metadata-edit-buttons v-if='drilldownIsViewMode'
      :investment='investment'/>
    <div class='pb-24 text-xs'
      :class='readBGStyle'>
      <custom-field v-if='!drilldownIsViewMode'
        :custom-field='slotBasedFields("회사", true)'
        :is-horizontal='horizontalInputs'
        :is-view-mode='drilldownIsViewMode'
        :class='fieldSpacing'>
        <entity-search
          v-model='investment.entity_id'
          class='inline-block flex-grow'
          :entity-name='investment.entity_name'
          :entity-id='investment.entity_id'
          @update-selected-entity='updateSelectedEntity' />
      </custom-field>
      <custom-field v-if='!drilldownIsViewMode'
        :custom-field='slotBasedFields("명칭")'
        :is-horizontal='horizontalInputs'
        :is-view-mode='drilldownIsViewMode'
        :class='fieldSpacing'>
        <input 
          v-model='investment.investment_name'
          class='form-input block flex-grow'>
      </custom-field>
      <header-divided-custom-fields v-for='(fields, index) in fieldsDividedByHeaders'
        :key='`headerSection-${index}`'
        :fields='fields'
        :is-horizontal='horizontalInputs' />

      <investment-details :is-view-mode='drilldownIsViewMode' />
    </div>
    <portal to='sidepanel-footer'>
      <investment-metadata-action-buttons v-if='!drilldownIsViewMode'
        :investment='investment'
        :is-valid-form='true'/>
    </portal>
  </div>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex'
import cloneDeep                            from 'lodash/cloneDeep'
import EntitySearch                    from '@/components/EntitySearch.vue'
import CustomField                     from '@/components/custom_fields/CustomField.vue'
import InvestmentDetails               from '@/components/investment/InvestmentDetails.vue'
import HeaderDividedCustomFields       from '@/views/dashboard/HeaderDividedCustomFields.vue'
import InvestmentMetadataActionButtons from '@/views/dashboard/InvestmentMetadataActionButtons.vue'
import InvestmentMetadataEditButtons   from '@/views/dashboard/InvestmentMetadataEditButtons.vue'


export default {
  name: 'InvestmentMetadata',
  components: {
    EntitySearch,
    CustomField,
    HeaderDividedCustomFields,
    InvestmentDetails,
    InvestmentMetadataActionButtons,
    InvestmentMetadataEditButtons,
  },
  data () {
    return {
      horizontalInputs: true,
      investment: {},
      showConfirmDelete: false,
    }
  },
  watch: {
    'drilldownInvestment.id': {
      handler: function () {
        this.investment = cloneDeep(this.drilldownInvestment)
        let lastDashboardViewId = localStorage.getItem('lastDashboardViewId')
        if (lastDashboardViewId) {
          this.getInvestmentCustomFields(lastDashboardViewId)
        } else {
          this.getInvestmentCustomFields()
        }
        this.getInvestmentDetails(this.drilldownInvestment.id)
        this.getListingsOnInvestment(this.drilldownInvestment.id)
      },
      immediate: true
    },
  },
  computed: {
    ...mapState('investments', [
      'drilldownInvestment',
      'drilldownIsViewMode'
    ]),
    ...mapState('listings', [
      'listings',
    ]),
    ...mapGetters('investments', [
      'isNewInvestment',
      'requiredEmptyFields',
      'customFields',
      'currentDashboardViewId'
    ]),
    ...mapGetters('dashboardViews', [
      'dashboardFiltersExpressions',
    ]),
    ...mapState('users', [
      'users'
    ]),
    fieldSpacing () {
      return (this.drilldownIsViewMode) ? 'my-2' : 'my-4'
    },
    readBGStyle () {
      return (this.drilldownIsViewMode) ? 'bg-gray-100 p-4 rounded-md mt-4' : ''
    },
    headerSortIndices () {
      return this.customFields.filter(field => field.field_type === 'sections').map(field => field.sort_index)
    },
    isMobile () {
      return window.navigator.userAgent.includes('Android') ||
             window.navigator.userAgent.includes('iPhone')
    },
    fieldsDividedByHeaders () {
      let arr = []
      if (this.headerSortIndices.length > 0) {
        let prev = cloneDeep(this.customFields)
        let arr2 = []
        let subIndex = 0
        this.headerSortIndices.forEach(sortIndex => {
          arr2 = prev.filter(field => field.sort_index < sortIndex)
          subIndex = arr2.length
          arr.push(arr2)
          prev.splice(0, subIndex)
        })
        arr2 = prev.filter(field => field.sort_index >= this.headerSortIndices[this.headerSortIndices.length-1])
        arr.push(arr2)

      } else {
        arr.push(this.customFields)
      }
      return arr
    },
  },
  methods: {
    ...mapActions('investments', [
      'getInvestmentCustomFields',
      'getInvestmentDetails',
    ]),
    ...mapActions('dashboardViews', [
      'getIndividualDashboardView',
    ]),
    ...mapActions('listings', [
      'getListingsOnInvestment',
    ]),
    ...mapActions('funds', [
      'getFunds',
    ]),
    slotBasedFields (fieldName, required) {
      return {
        custom_field_name: fieldName,
        field_type: 'slotBased',
        required: required,
        rules: [],
      }
    },
    updateInvestmentName () {
      // only update if there is none
      if (!this.investment.investment_name) {
        this.investment.investment_name = this.investment.entity_name
      }
    },
    updateSelectedEntity (entity) {
      this.investment.entity_id = entity.entityId
      this.investment.entity_name = entity.entityName
      this.updateInvestmentName()
    },
  },
  mounted () {
    this.getFunds()
    this.horizontalInputs = !this.isMobile

    if (this.currentDashboardViewId === 0) {
       this.getIndividualDashboardView(localStorage.getItem('lastDashboardViewId'))
    }
  }
}
</script>
