<template>
  <div>
    <div class='w-full flex flex-row justify-between'>
      <button class='block border px-12 py-2 text-sm rounded-md uppercase hover:border-blue-300 hover:shadow-md'
        @click='editData'>
        Data 수정
      </button>
      <div class='flex-grow flex flex-row justify-end items-center'>
        <select
          v-model='updateToStatus'
          class='py-3 px-2 border border-transparent hover:border-gray-200 rounded-md text-sm uppercase'
          @change='openUpdate'>
          <option
            disabled
            value=''>
            상태: {{ investment.investment_status }}
          </option>
          <option v-for='updateStatus in selectableUpdateStatuses'
            :key='`updateToStatus-${updateStatus}`'
            :value='updateStatus'>
            {{ updateStatus }}
          </option>
        </select>
        <el-popover
          v-model='showDeleteConfirm'
          placement='left'
          width='320'
          trigger='click'
          :open-delay='0'
          :close-delay='0'>
          <div class='px-8 py-2 text-center'>
            <h4 class='mb-6'>
              <span class='font-semibold'>{{investment.entity_name}}-{{investment.investment_name}}</span>
              삭제하시겠습니까?
            </h4>
            <div class='flex flex-row justify-center items-center gap-x-4'>
              <button
                @click='showDeleteConfirm = false'
                class='py-3 px-12 rounded-md text-sm bg-gray-900 text-white opacity-90 hover:opacity-100 hover:shadow'>
                유지
              </button>
              <button
                @click='submitDelete' 
                class='p-3 text-red-700 text-sm border border-gray-50 hover:border-red-400 rounded-md hover:shadow'>
                삭제 확인
              </button>
            </div>
          </div>
          <button
            slot='reference'
            class='p-3 text-red-700 text-sm border border-transparent hover:border-red-400 hover:shadow-md rounded-md'>
            삭제
          </button>
        </el-popover>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import DefaultObjects  from '@/utils/default-objects'


export default {
  name: 'InvestmentMetadataEditButtons',
  props: [ 'investment'],
  data () {
    return {
      updateToStatus: '',
      showDeleteConfirm: false,
    }
  },
  computed: {
    selectableUpdateStatuses () {
      return DefaultObjects.selectableInvestmentStatuses().filter(status => status !== this.investment.investment_status)
    },
  },
  methods: {
    ...mapActions([
      'sidepanelClose',
    ]),
    ...mapActions('investments', [
      'updateInvestmentStatus',
      'deleteInvestment',
      'resetEditing',
    ]),
    ...mapMutations('investments', [
      'setDrilldownIsViewMode',
    ]),
    submitDelete () {
      this.showDeleteConfirm = false
      this.deleteInvestment(this.investment.id).then(() => {
        this.resetEditing()
        this.sidepanelClose()
      })
    },
    editData () {
      this.setDrilldownIsViewMode(false)
    },
    openUpdate () {
      console.log('update to ', this.updateToStatus)
      this.$confirm(`${this.updateToStatus} 으로 수정하시겠습니까?`, 'Warning', {
        confirmButtonText: '수정',
        cancelButtonText: `유지`,
        type: 'warning'
      }).then(() => {
        this.updateInvestmentStatus({investmentId: this.investment.id, investmentStatus: this.updateToStatus}).then(() => {
          this.resetEditing()
          this.sidepanelClose()
          this.$message({
            type: 'success',
            message: '수정 완료'
          })
        })
      }).catch(() => {
        this.updateToStatus = ''
      })
    },
  },
}
</script>
