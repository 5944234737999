<template>
  <div>
    <h3 class='text-gray-900 text-lg font-semibold uppercase w-1/3 mt-6'>투자정보</h3>
    <table v-if='isViewMode' class='mt-6'>
      <thead>
        <th class='border text-left px-2 py-2 text-sm whitespace-no-wrap'>직접투자여부</th>
        <th class='border text-left px-2 py-2 text-sm whitespace-no-wrap'>자산구분</th>
        <th class='border text-left px-2 py-2 text-sm whitespace-no-wrap'>투자형태</th>
        <th class='border text-left px-2 py-2 text-sm whitespace-no-wrap'>신주/구주</th>
        <th class='border text-right px-2 py-2 text-sm whitespace-no-wrap'>투자수량</th>
        <th class='border text-right px-2 py-2 text-sm whitespace-no-wrap'>투자금액</th>
        <th class='border text-right px-2 py-2 text-sm whitespace-no-wrap'>투자단가</th>
        <th class='border text-right px-2 py-2 text-sm whitespace-no-wrap'>투자비율</th>
        <th class='border text-left px-2 py-2 text-sm whitespace-no-wrap'>투자예정일</th>
        <th class='border text-left px-2 py-2 text-sm whitespace-no-wrap'>회수예정일</th>
        <th class='border text-left px-2 py-2 text-sm whitespace-no-wrap'>발행일자</th>
        <th class='border text-left px-2 py-2 text-sm whitespace-no-wrap'>만기일자</th>
        <th class='border text-right px-2 py-2 text-sm whitespace-no-wrap'>Coupon Rate</th>
        <th class='border text-right px-2 py-2 text-sm whitespace-no-wrap'>우선배당</th>
      </thead>
      <tbody>
        <tr
          v-for='(details, index) in drilldownInvestmentDetails'
          :key='`view-investment-details-${JSON.stringify(details)}-${index}`'>
          <td class='border text-left px-2 py-2 whitespace-no-wrap'>{{ directInvestmentLabel(details) }}</td>
          <td class='border text-left px-2 py-2 whitespace-no-wrap'>{{ details.asset_class }}</td>
          <td class='border text-left px-2 py-2 whitespace-no-wrap'>{{ details.fund_form }}</td>
          <td class='border text-left px-2 py-2 whitespace-no-wrap'>{{ newInvestmentLabel(details) }}</td>
          <td class='border text-right px-2 py-2 whitespace-no-wrap'>{{ numberStyle(details.investment_quantity) }}</td>
          <td class='border text-right px-2 py-2 whitespace-no-wrap'>{{ numberStyle(details.investment_amount) }}</td>
          <td class='border text-right px-2 py-2 whitespace-no-wrap'>{{ numberStyle(details.investment_price) }}</td>
          <td class='border text-right px-2 py-2 whitespace-no-wrap'>{{ details.investment_ratio }}</td>
          <td class='border text-left px-2 py-2 whitespace-no-wrap'>{{ details.investment_date }}</td>
          <td class='border text-left px-2 py-2 whitespace-no-wrap'>{{ details.collection_date }}</td>
          <td class='border text-left px-2 py-2 whitespace-no-wrap'>{{ details.start_date }}</td>
          <td class='border text-left px-2 py-2 whitespace-no-wrap'>{{ details.end_date }}</td>
          <td class='border text-right px-2 py-2 whitespace-no-wrap'>{{ details.coupon_rate }}</td>
          <td class='border text-right px-2 py-2 whitespace-no-wrap'>{{ details.preferred_dividend }}</td>
        </tr>
      </tbody>
    </table>
    <div v-else>
      <table class='table-auto mt-6'>
        <thead>
          <th class='border text-left px-2 py-2 text-sm whitespace-no-wrap'>직접투자여부</th>
          <th class='border text-left px-2 py-2 text-sm whitespace-no-wrap'>자산구분</th>
          <th class='border text-left px-2 py-2 text-sm whitespace-no-wrap'>투자형태</th>
          <th class='border text-left px-2 py-2 text-sm whitespace-no-wrap'>신주/구주</th>
          <th class='border text-left px-2 py-2 text-sm whitespace-no-wrap'>투자수량</th>
          <th class='border text-left px-2 py-2 text-sm whitespace-no-wrap'>투자금액</th>
          <th class='border text-left px-2 py-2 text-sm whitespace-no-wrap'>투자단가</th>
          <th class='border text-left px-2 py-2 text-sm whitespace-no-wrap'>투자비율</th>
          <th class='border text-left px-2 py-2 text-sm whitespace-no-wrap'>투자예정일</th>
          <th class='border text-left px-2 py-2 text-sm whitespace-no-wrap'>회수예정일</th>
          <th class='border text-left px-2 py-2 text-sm whitespace-no-wrap'>발행일자</th>
          <th class='border text-left px-2 py-2 text-sm whitespace-no-wrap'>만기일자</th>
          <th class='border text-left px-2 py-2 text-sm whitespace-no-wrap'>Coupon Rate</th>
          <th class='border text-left px-2 py-2 text-sm whitespace-no-wrap'>우선배당</th>
          <th class='border px-2 py-2'></th>
        </thead>
        <tbody>
          <tr
            v-for='(details, index) in drilldownInvestmentDetails'
            :key='`edit-investment-details-${JSON.stringify(details)}-${index}`'>
            <td class='border text-left px-2 py-2'>
              <v-select
                v-model='details.is_direct_investment'
                :options='directInvestmentOptions'
                :reduce='option => option.value'
                :close-on-select='true'
                class='w-24'>
              </v-select>
            </td>
            <td class='border text-left px-2 py-2'>
              <v-select
                v-model='details.asset_class'
                :options='assetClassOptions'
                :close-on-select='true'
                class='w-32'>
              </v-select>
            </td>
            <td class='border text-left px-2 py-2'>
              <v-select
                v-model='details.fund_form'
                :options='fundFormOptions'
                :close-on-select='true'
                class='w-32'>
              </v-select>
            </td>
            <td class='border text-left px-2 py-2'>
              <v-select
                v-model='details.is_new_shares'
                :options='newInvestmentOptions'
                :reduce='option => option.value'
                :close-on-select='true'
                class='w-24'>
              </v-select>
            </td>
            <td class='border px-2 py-2'>
              <vue-numeric
                v-model='details.investment_quantity'
                output-type='number'
                class='form-input w-24 text-right'
                :minus='true'
                separator=','></vue-numeric>
            </td>
            <td class='border px-2 py-2'>
              <vue-numeric
                v-model='details.investment_amount'
                output-type='number'
                class='form-input w-40 text-right'
                :minus='true'
                separator=','></vue-numeric>
            </td>
            <td class='border px-2 py-2'>
              <vue-numeric
                v-model='details.investment_price'
                output-type='number'
                class='form-input w-24 text-right'
                :minus='true'
                separator=','></vue-numeric>
            </td>
            <td class='border px-2 py-2'>
              <vue-numeric
                v-model='details.investment_ratio'
                output-type='number'
                class='form-input w-16 text-right'
                :minus='true'
                separator=','></vue-numeric>
            </td>
            <td class='border text-left px-2 py-2'>
              <el-date-picker
                v-model='details.investment_date'
                style='width: 10rem'
                type='date' placeholder='Pick a day'
                value-format='yyyy-MM-dd' />
            </td>
            <td class='border text-left px-2 py-2'>
              <el-date-picker
                v-model='details.collection_date'
                style='width: 10rem'
                type='date' placeholder='Pick a day'
                value-format='yyyy-MM-dd' />
            </td>
            <td class='border text-left px-2 py-2'>
              <el-date-picker
                v-model='details.start_date'
                style='width: 10rem'
                type='date' placeholder='Pick a day'
                value-format='yyyy-MM-dd' />
            </td>
            <td class='border text-left px-2 py-2'>
              <el-date-picker
                v-model='details.end_date'
                style='width: 10rem'
                type='date' placeholder='Pick a day'
                value-format='yyyy-MM-dd' />
            </td>
            <td class='border px-2 py-2'>
              <vue-numeric
                v-model='details.coupon_rate'
                output-type='number'
                class='form-input w-16 text-right'
                :minus='true'
                separator=','></vue-numeric>
            </td>
            <td class='border px-2 py-2'>
              <vue-numeric
                v-model='details.preferred_dividend'
                output-type='number'
                class='form-input w-16 text-right'
                :minus='true'
                separator=','></vue-numeric>
            </td>
            <td class='border text-center px-2 py-2'>
              <button
                @click='removeRow(details.id, index)'>
                <x-circle-icon class='inline-bock text-red-900 opacity-75' />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <button
        @click='addNewDetails'
        class='mt-2 mb-4 px-4 rounded text-sm border border-transparent hover:border-blue-300 hover:shadow'>
        <plus-circle-icon class='inline-block text-gray-700' style='margin-top: -4px' />
        추가
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState }        from 'vuex'
import { mapMultiRowFields } from 'vuex-map-fields'
import cloneDeep from 'lodash/cloneDeep'
import { PlusCircleIcon, XCircleIcon } from '@vue-hero-icons/outline'

export default {
  name: 'InvestmentDetails',
  components: { PlusCircleIcon, XCircleIcon },
  data () {
    return {
      assetClassOptions: ['CB', 'EB', 'BW', 'CPS', 'RCPS', '보통주', 'Warrant', '사모사채', '기타'],
      directInvestmentOptions: [
        { label: '직접', value: true },
        { label: '간접', value: false },
      ],
      newInvestmentOptions: [
        { label: '신주', value: true },
        { label: '구주', value: false },
      ],
      newDetails: {
        is_direct_investment: true,
        asset_class: '',
        fund_form: '',
        is_new_shares: true,
        investment_quantity: 0,
        investment_amount: 0,
        investment_price: 0,
        investment_ratio: 0,
        investment_date: null,
        collection_date: null,
        start_date: null,
        end_date: null,
        coupon_rate: 0,
        preferred_dividend: 0,
      }
    }
  },
  props: [
    'isViewMode',
  ],
  computed: {
    ...mapState('investments', [
      'drilldownIsViewMode',
      'fundFormOptions',
    ]),
    ...mapState('investments', {
      newInvestmentDetails: 'drilldownInvestmentDetails',
    }),
    ...mapMultiRowFields('investments', ['drilldownInvestmentDetails']),
  },
  methods: {
    ...mapActions('investments', [
      'deleteInvestmentDetails',
    ]),
    ...mapMutations('investments', [
      'addToDrilldownInvestmentDetails',
      'removeFromDrilldownInvestmentDetailsByRowIndex',
    ]),
    addNewDetails () {
      this.addToDrilldownInvestmentDetails(cloneDeep(this.newDetails))
    },
    directInvestmentLabel (details) {
      return details.is_direct_investment ? '직접' : '간접'
    },
    newInvestmentLabel (details) {
      return details.is_new_shares ? '신주' : '구주'
    },
    removeRow (detailsId, rowIndex) {
      if (detailsId) {
        // delete details
        this.deleteInvestmentDetails(detailsId)
      } else {
        this.removeFromDrilldownInvestmentDetails(rowIndex)
      }
    },
    numberStyle (number) {
      return number ? Number(number).toLocaleString() : ''
    },
  },
}
</script>
